import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import { navigate } from 'gatsby';

import { getApiVersion } from '../../actions/versionActions';
import logo from '../../images/fintegrity-logo.png';
import { AppState } from '../../reducers';
import { routerControlSetAction } from '../../reducers/routerControlReducer';
import styles from './styles';

const Footer = ({ classes }: WithStyles<typeof styles>): ReactElement => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleZN = (): void => {
    i18n.changeLanguage('zhCN');
  };
  const handleEN = (): void => {
    i18n.changeLanguage('enUS');
  };
  const { urlHistory } = useSelector((appState: AppState) => appState.routerControlReducer);
  const { appVersion, apiVersion } = useSelector((appState: AppState) => appState.version);
  if (appVersion) console.log('App version', appVersion);
  if (apiVersion) console.log('Api version', apiVersion);

  useEffect(() => {
    if (!apiVersion) dispatch(getApiVersion());
  }, [apiVersion]);

  useEffect(() => {
    if (urlHistory && urlHistory?.length > 0) {
      if (window.location.pathname === '/') {
        dispatch(routerControlSetAction({ urlHistory: [] }));
      }
      if (
        urlHistory[urlHistory.length - 1] === window.location.pathname &&
        urlHistory[urlHistory.length - 2] === window.location.pathname
      ) {
        dispatch(routerControlSetAction({ urlHistory: [] }));
        navigate('/');
      }
    }
  }, [urlHistory]);

  useEffect(() => {
    dispatch(routerControlSetAction({ urlHistory: [...urlHistory, window.location.pathname] }));
  }, []);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <hr className={classes.line} />
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Button aria-haspopup="true" href="/" className={classes.block}>
                首页
              </Button>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <Button aria-haspopup="true" href="#company" className={classes.block}>
                Company
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button aria-haspopup="true" href="#portfolio" className={classes.block}>
                Portfolio
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button href="#blog" className={classes.block} aria-haspopup="true">
                Blog
              </Button>
            </ListItem> */}
            <ListItem className={classes.inlineBlock}>
              <div>
                <Button aria-haspopup="true" onClick={handleClick} className={classes.block}>
                  Language
                  <LanguageIcon style={{ position: 'absolute', fontSize: 16 }}></LanguageIcon>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.block}
                >
                  <MenuItem onClick={handleZN}>中文🇨🇳</MenuItem>
                  <MenuItem onClick={handleEN}>English🇬🇧</MenuItem>
                </Menu>
              </div>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Typography variant="subtitle2" align="center" style={{ marginLeft: '20px' }}>
                <a
                  href="https://www.fintegrity.cn/user"
                  target="_blank"
                  className={classes.a}
                  rel="noopener noreferrer"
                >
                  用户协议
                </a>
                <a
                  href="https://www.fintegrity.cn/privacy"
                  target="_blank"
                  className={classes.a}
                  rel="noopener noreferrer"
                >
                  查看隐私政策
                </a>
              </Typography>
            </ListItem>
          </List>
        </div>

        <p className={classes.right}>
          <span>测试版若遇技术问题请联系(contact@fintegrity.cn) &nbsp;</span>
          <span>傅通自业内测 &nbsp;</span>
          <Link
            href="https://blog.chun.no"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.a}
          >
            <span>Fintegrity &copy;</span>
            <span>
              {new Date().getFullYear()} &nbsp;
              <img src={logo} alt="logo" className={classes.img} />
            </span>
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default withStyles(styles)(Footer);
