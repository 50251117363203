import { createStyles } from '@material-ui/core';

import { container, defaultFont, grayfColor, primaryfColor } from '../../styles/styles';

const footerStyles = createStyles({
  block: {
    color: 'inherit',
    padding: '15px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
    ...defaultFont,
    fontWeight: 500,
    fontSize: '12px',
  },
  left: {
    float: 'left',
    display: 'block',
  },
  right: {
    padding: '15px 0',
    margin: '0',
    marginRight: '15px',
    fontSize: '14px',
    float: 'right',
  },

  footer: {
    bottom: '0',
    borderTop: '1px solid ' + grayfColor[11],
    padding: '15px 0',
    ...defaultFont,
    width: '100%',
  },
  container,
  a: {
    color: primaryfColor[0],
    textDecoration: 'none',
    backgroundColor: 'transparent',
    margin: '0 10px',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  img: {
    width: 25,
    marginBottom: 0,
  },
  line: {
    color: grayfColor,
    marginBottom: '5px',
  },
});

export default footerStyles;
