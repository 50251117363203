import { showNotificationAction } from '../reducers/notificationReducer';
import { apiVersionKnownAction } from '../reducers/versionReducer';
import debug from '../utils/debug';
import { query } from '../utils/graphql';
import { GET_API_INFO } from '../utils/graphql/app';

export const getApiVersion: Function = () => async (dispatch: Function): Promise<boolean> => {
  debug('Getting API version');
  const { data } = await query(GET_API_INFO);
  if (data?.getApiVersion) {
    dispatch(apiVersionKnownAction(data?.getApiVersion));
    return true;
  }
  dispatch(showNotificationAction({ severity: 'warning', message: 'Failed to get API version' }));
  return false;
};
